import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

const ListLink = props => (
  <li className="nav-link">
    <Link to={props.to} className="nav-link-a">
      {props.children}
    </Link>
  </li>
)

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuClass: "menu menuOpen",
      navListDisplay: "none",
    }
  }

  toggleMenu() {
    console.log("open")
    if (this.state.navListDisplay === "none") {
      this.setState({
        menuClass: "menu menuClose",
        navListDisplay: "inline-block",
      })
    } else {
      this.setState({ menuClass: "menu menuOpen", navListDisplay: "none" })
    }
  }

  render() {
    return (
      <nav className="nav">
        <button
          className={this.state.menuClass}
          onClick={() => this.toggleMenu()}
          aria-label="Menu"
        ></button>
        <ul
          className="nav-list"
          id="nav-list"
          style={{ display: this.state.navListDisplay }}
        >
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/portfolio/">Portfolio</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </nav>
    )
  }
}

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Ronda Lunn, graphic artist and web designer"
        />
        <title>{data.site.siteMetadata.title}</title>
        <link rel="canonical" href="https://rondalunn.com" />
        <html lang="en" />        
      </Helmet>
      <div className="container">
      <header className="header">
        <Link to="/">
          <h1 className="header-title">Ronda Lunn</h1>
        </Link>
        <Menu />
      </header>
      <div className="content">
        {children}
      </div>
      <footer>© 2019 Ronda Lunn</footer>
      </div>

    </div>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Layout
