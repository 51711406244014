import React from "react"
import Layout from "../components/layout"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      website: "",
      message: "",
      mailSent: false,
      error: null,
    }
  }

  handleFormSubmit(event) {
    console.log(this.state)

    //event.preventDefault()
    // const bodyParser = require("body-parser")
    // const express = require("express")
    // const nodemailer = require("nodemailer")

    // const app = express()
    // app.use(bodyParser.urlencoded())

    // const contactAddress = "ronda@rondaedwards.com"

    // const mailer = nodemailer.createTransport({
    //   service: "Gmail",
    //   auth: {
    //     user: process.env.production.GMAIL_ADDRESS,
    //     pass: process.env.production.GMAIL_PASSWORD,
    //   },
    // })

    // app.post("/contact", function(req, res) {
    //   mailer.sendMail(
    //     {
    //       from: req.body.from,
    //       to: [contactAddress],
    //       subject: req.body.subject || "[No subject]",
    //       html: req.body.message || "[No message]",
    //     },
    //     function(err, info) {
    //       if (err) return res.status(500).send(err)
    //       res.json({ success: true })
    //     }
    //   )
    // })

    // app.listen(3000)

    //   grecaptcha.ready(function() {
    //     grecaptcha.execute('6LdEG7IUAAAAAHENVusRPfJo4E0PLNtAygmP6hR9', {action: 'https://rondalunn.com'}).then(function(token) {
    //
    //     });
    // });
    /////////////////////////////////////

    /* <script src="https://www.google.com/recaptcha/api.js?render=6LdEG7IUAAAAAHENVusRPfJo4E0PLNtAygmP6hR9"></script>
  <script>
  grecaptcha.ready(function() {
      grecaptcha.execute('6LdEG7IUAAAAAHENVusRPfJo4E0PLNtAygmP6hR9', {action: 'homepage'}).then(function(token) {
         ...
      });
  });
  </script> */

    /////////////////////////////

    /*axios({
      method: 'post',
      url: `${"../components/Contact.php"}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent
        })
      })
      .catch(error => this.setState({ error: error.message }));*/
  }

  render() {
    return (
      <Layout>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
        <div className="contact-form-container">
          <form className="contact-form" method="post" action="emailform.php">
            <h1>Contact Ronda</h1>
            <input type="text" name="_gotcha" style={{ display: "none" }} />
            <fieldset>
              <label>
                Name*
                <input
                  placeholder="Your name"
                  id="name"
                  name="name"
                  type="text"
                  tabindex="1"
                  required
                  autofocus
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Email*
                <input
                  placeholder="Your Email Address"
                  id="email"
                  name="email"
                  type="email"
                  tabindex="2"
                  required
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Phone
                <input
                  placeholder="Your Phone Number"
                  id="phone"
                  name="phone"
                  type="tel"
                  tabindex="3"
                  value={this.state.phone}
                  onChange={e => this.setState({ phone: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Website
                <input
                  placeholder="Your Web Site starts with http://"
                  id="website"
                  name="website"
                  type="url"
                  tabindex="4"
                  value={this.state.website}
                  onChange={e => this.setState({ website: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Message*
                <textarea
                  placeholder="Type your Message Here...."
                  id="message"
                  name="message"
                  tabindex="5"
                  required
                  value={this.state.message}
                  onChange={e => this.setState({ message: e.target.value })}
                ></textarea>
              </label>
              <h6 style={{ textAlign: "right", margin: 0 }}>*Required</h6>
            </fieldset>
            <fieldset>
              <div
                class="g-recaptcha"
                data-sitekey="6LcqBlAUAAAAACDmNf7nrAtYTcTI0JSmWvTdxNRf"
              ></div>
            </fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
                onClick={e => this.handleFormSubmit(e)}
              >
                Submit
              </button>
            </fieldset>
            <div>
              {this.state.mailSent && (
                <div>Thank you. Your message was submitted.</div>
              )}
            </div>
          </form>
        </div>
      </Layout>
    )
  }
}
